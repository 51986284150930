@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;



/* purgecss start ignore */
html,
body {
  font-family: 'Work Sans', sans-serif;
  background-color: #f8f9fe;
}

.overflow-auto-y {
  overflow-y: auto !important;
}


.slide-track {
  width: calc(240px * 34);
  animation: scroll 80s linear infinite;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-240px * 17));
  }
}

.slide-track:hover {
  animation-play-state: paused;
}



/* custom spinner */

.spinner{
  animation: spin 1s ease infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
    }
}


/* remove input border, no se quitaba con tw */
input:hover, 
input:focus{
    outline:0px !important;
    -webkit-appearance:none;
    box-shadow: none !important;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
  scroll-behavior: smooth;
}

/* carousel */
.carousel-img{
  transition: 1s;
}

.carousel-present {
  left: 0;
  opacity: 1;
  visibility: visible;
  position: absolute;

}

.carousel-next {
  position: absolute;
  left: 0vw;
  
  opacity: 0;
  visibility: hidden;
  animation: fadeIn 5s;
}

/* Flipping animation */
.flip-card {
  background-color: transparent;
  perspective: 1000px;
}

.flip-card-inner {
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.first-flip-card:hover .flip-card-inner,
.flip-card-back {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Scrollbar */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
*::-webkit-scrollbar-track {
  background-color: #DEDEDE ;
  border-radius: 10px;
}
*::-webkit-scrollbar-thumb {
  background-color: #4FD6C9;
  border-radius: 14px;
}



#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #5145cd;
  position: fixed;
  z-index: 1060;
  top: 0;
  left: 0;

  width: 100%;
  height: 3px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #5145cd, 0 0 5px #5145cd;
  opacity: 1;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: #5145cd;
  border-left-color: #5145cd;
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* purgecss end ignore */
